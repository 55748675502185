<template>
  <div class="companyPageWrap en">
    <m-layout-bar>
      <div class="companyWrap" :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}">
        <div class="content">
          <div class="layout_left">
            <div class="title">Company profile</div>
            <div class="detail">
              <p>
                This is a place full of joy and childlike interest. It is named after Dendrobium, which has
                a curved shape and smooth taste, Subverting the inherent concept of domestic
                unpowered Park, it is currently the largest yo theme park in China, with flowers
                Amusement projects such as the sea and the waves have swept away the thrills,
                excitement, delicious food and beautiful scenery.
              </p>
              <p>
                Tieding Youliu project is located in Yueqing City, Zhejiang Province. It is planned,
                operated and invested by Yueqing manfangshi Cultural Tourism Co., Ltd The total
                amount is about 1.15 billion yuan. Relying on the beautiful scenery and profound
                culture of Yandang Mountain, the project gathers theme leisure, rural home stay
                Integrating cultural and creative design and popular science education, it pioneered
                the mode of "Dendrobium candidum + cultural and creative tourism", covering three
                theme parks Four seasons flowers and fruits and six functional sections fully meet
                the needs of tourists for leisure and recreation, parent-child interaction, science
                popularization and research all year round Health care needs.
              </p>
              <!-- <img class="dec" :src="require('../assets/image/company/dec.png')" alt /> -->
              <div class="dec">
                <img class="dec" :src="require('../assets/image/company/d1.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d2.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d3.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d4.png')" alt />
              </div>
            </div>
          </div>
          <div class="layout_right">
            <img :src="require('../assets/image/company/img.png')" alt />
          </div>

          <!-- contact us -->
          <div class="contactWrap">
            <div class="titleWrap">
              <img :src="require('../assets/image/company_en/titleLeft.png')" alt />
              <span>Contact us</span>
              <img :src="require('../assets/image/company_en/titleRight.png')" alt />
            </div>
            <div class="content">
              <img class="map" :src="require('../assets/image/company_en/map.png')" alt />
              <div class="layoutRight">
                <div class="group">
                  <img
                    class="iconAddress"
                    :src="require('../assets/image/company_en/address.png')"
                    alt
                  />
                  <span class="detail">
                    Address: xiashantou village, Dajing Town, Yueqing
                    City, Zhejiang Province
                  </span>
                </div>
                <div class="group">
                  <img class="iconPhone" :src="require('../assets/image/company_en/phone.png')" alt />
                  <span class="title">Telephone:</span>
                  <span class="phone">400-661-5666</span>
                </div>
                <div class="group">
                  <img class="iconMail" :src="require('../assets/image/company_en/mail.png')" alt />
                  <span class="title">mailbox:</span>
                  <span class="phone">mfstdll@chinatdll.com</span>
                </div>
                <img :src="require('../assets/image/company_en/dec2.png')" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      bgImgUrl: require("../assets/image/company_en/bg.png"),
      rcImgUrl: require("../assets/image/company/recru_bg.png")
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.companyWrap {
  // border: 1px solid transparent;
  overflow: hidden;
  > .content {
    width: 1400px;
    // min-height: 1979px;
    background: $main-white;
    padding: 66px 38px 96px 74px;
    border: 1px solid transparent;
    margin: 47px auto 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    > .layout_left,
    > .layout_right {
      display: inline-block;
      height: 741px;
      vertical-align: top;
    }
    > .layout_left {
      position: relative;
      width: 661px;
      > .title {
        font-size: 70px;
        color: $font-greenLightt;
      }
      > .detail {
        font-size: 18px;
        color: $main-black;
        line-height: 1.7;
        height: 452px;
        overflow: hidden;
        > .dec {
          display: flex;
          justify-content: space-between;
          position: absolute;
          width: 653px;
          height: 64px;
          bottom: 100px;
          > img {
            flex: none;
          }
        }
      }
    }
    > .layout_right {
      width: 624px;
      //  background:blue;
    }

    > .contactWrap {
      > .titleWrap {
        position: relative;
        height: 77px;
        // padding-left: 278px;
        text-align: center;

        > span {
          display: inline-block;
          width: 444px;
          height: 77px;
          font-size: 70px;
          color: $font-greenLightt;
          text-align: center;
        }
      }
      > .content {
        position: relative;
        margin-top: 25px;
        > img {
          vertical-align: top;
        }
        > .layoutRight {
          position: absolute;
          top: 0;
          right: -20px;
          display: inline-block;
          width: 425px;
          height: 528px;
          padding-top: 25px;
          box-sizing: border-box;

          > .group {
            margin-bottom: 53px;
            > img {
              margin-right: 5px;
            }
            > .iconAddress {
              vertical-align: top;
            }
            > .iconPhone,
            > .iconMail {
              vertical-align: middle;
            }
            > .title {
              font-size: 16px;
              color: $font-grayDark;
              margin-right: 8px;
            }
            > .phone {
              font-size: 24px;
            }
            .detail {
              display: inline-block;
              width: 372px;
              height: 44px;
              font-size: 16px;
              color: $font-grayDark;
            }
          }
        }
      }
    }
  }
}
</style>
